import React, { useState } from 'react';
import FindYourProductData from '../../data/find-your-product.json'
import { Check, Cross } from "~/components/elements/Icon";
import {InteractiveMap} from "~/components/flexible/InteractiveMap";
import { AnimatePresence, motion } from "framer-motion";

export const FindYourProduct = (page) => {

	const [active, setActive] = useState(0)

	return (
		<section className="bg-blue-light">
			<div className="container pt-40 pb-20 ">
				<AnimatePresence>
					{FindYourProductData.map((data, i) => (
						<>
							<Slide i={i} {...data} length={FindYourProductData.length} active={active} setActive={setActive} />
						</>
					))}

					{/* <SlideFeaturedProducts /> */}
					{/* <SlideSupport /> */}
				</AnimatePresence>
			</div>
		</section>
	);
};

export const Slide = ({ showLength = true, question, type, options, i, length, active, setActive }) => {
	return (
		<>
			{active === i && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.5, ease: "easeInOut" }}
					className="text-center min-h-[600px] h-[calc(100vh-240px)] pt-12 md:pt-28 flex flex-col items-center  space-y-12"
					onClick={() => setActive((i + 1))}>
					{type === 'map' ? (
						<div>
							<InteractiveMap heading="Test" />
						</div>
					) : (
						<>
							<div className="space-y-4 max-w-[628px] mx-auto">
								{showLength && <div className="t-14 font-semibold text-blue">{i + 1} of {length}</div>}
								<h2 className="t-42">{question}</h2>
							</div>
							{type === 'true-false' ? (
								<TrueFalse />
							) : type === 'select' ? (
								<Select options={options} />
							) : null}
						</>
					)}
				</motion.div>
			)}
		</>
	)
}

export const SlideMap = ({ showLength = true, question, type, options, i, length, active, setActive }) => {
	return (
		<>
			{active === i && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.5, ease: "easeInOut" }}
					className="text-center min-h-[600px] h-[calc(100vh-240px)] flex flex-col items-center justify-center space-y-12"
					onClick={() => setActive((i + 1))}>
					<div className="space-y-4 max-w-[628px] mx-auto">
						{showLength && <div className="t-14 font-semibold text-blue">{i + 1} of {length}</div>}
						<h2 className="t-42">{question}</h2>
					</div>
				</motion.div>
			)}
		</>
	)
}


export const TrueFalse = () => {

	return (
		<div className="grid grid-cols-2 w-full max-w-[342px] mx-auto gap-x-6">
			<TrueFalseCard label={"Yes"} value={true} />
			<TrueFalseCard label={"No"} value={false} />
		</div>
	)
}

export const TrueFalseCard = ({ label, value }) => {
	return (
		<button className="bg-white rounded-[20px] card-shadow flex flex-col space-y-8 items-center justify-center py-10 px-5 h-[177px]  border border-white transition-colors duration-300 ease-in-out hover:border-blue">
			<div className="text-blue ">
				{value === true ? (
					<Check />
				) : (
					<Cross />
				)}
			</div>
			<div className="t-18 font-heading font-bold">
				{label}
			</div>
		</button>
	)
}


export const Select = ({ options }) => {
	return (
		<div className="flex flex-col space-y-4 w-full max-w-[384px] mx-auto">
			{options.map((option, i) => (
				<SelectOption {...option} />
			))}
		</div>
	)
}

export const SelectOption = ({ label, id }) => {
	return (
		<button className="bg-white text-navy rounded-[20px] card-shadow py-5 px-10 text-center border border-white transition-colors duration-300 ease-in-out hover:border-blue">
			<div className="t-18 font-heading font-bold">
				{label}
			</div>
		</button>
	)
}
