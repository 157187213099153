import React from 'react';
import { BookAConsultation } from '~/components/landing/BookAConsultation';
import { FindYourProduct } from '~/components/landing/FindYourProduct';
import { Legal } from '~/components/landing/Legal';
import { Faqs } from '~/components/landing/Faqs';
import { Resources } from '~/components/landing/Resources';
import { Products } from '~/components/landing/Products';

export const LandingPage = (page) => {
  const { layout } = page;

  if (layout === 'book-consultation') {
    return <BookAConsultation {...page} />;
  } else if (layout === 'find-your-product') {
    return <FindYourProduct {...page} />;
  } else if (layout === 'legal') {
    return <Legal {...page} />;
  } else if (layout === 'faqs') {
    return <Faqs {...page} />;
  } else if (layout === 'resources') {
    return <Resources {...page} />;
  } else if (layout === 'products') {
    return <Products {...page} />;
  } else {
    console.log('IMPLEMENT', page);
    return null;
  }
};
