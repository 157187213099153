import React, { useLayoutEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useRef } from 'react';
import { useEffect } from 'react';
import { ChevronRight, Search, Star } from '~/components/elements/Icon';
import { MaybeLink, LinkInner } from '~/components/elements/Link';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, navigate } from 'gatsby';
import { Manufacturers } from '~/components/flexible/ShopBy';
import queryString, { parse } from 'query-string'
import useProductAttributes from '~/hooks/useProductAttributes';

export const Products = (page) => {

	return (
		<>
			<ProductsInner />
		</>
	);
};


export const ProductsInner = () => {

	const [brands, setBrands] = useState([])
	const [levels, setLevels] = useState([])
	const [features, setFeatures] = useState([])
	const [isInit, setIsInit] = useState(false)

	useEffect(() => {

		let parsed

		if (typeof window !== `undefined`) {
			parsed = queryString.parse(location.search, { arrayFormat: 'comma' });
		}

		if (parsed.brands) {
			setBrands(parsed.brands)
		}

		if (parsed.features) {
			setFeatures(parsed.features)
		}

		if (parsed.levels) {
			setLevels(parsed.levels)
		}

	}, [])

	useEffect(() => {
		let parsed

		if (typeof window !== `undefined`) {
			parsed = queryString.parse(location.search, { arrayFormat: 'comma' });
		}

		parsed.brands = brands
		parsed.levels = levels
		parsed.features = features

		if (isInit) {
			const stringified = queryString.stringify(parsed, { arrayFormat: 'comma' })
			const newUrl = window.location.pathname + '?' + stringified

			navigate(newUrl)
		}

		setIsInit(true)

	}, [brands, levels, features, isInit])

	return (
		<section className="section section-bot bg-blue-light border-t border-t-black border-opacity-10" id="#products">
			<div className="container">
				<div className="space-y-10 lg:space-y-0 lg:grid grid-cols-12 gap-x-10">
					<div className="col-span-3">
						<div className="relative h-full">
							<div className="bg-white card-shadow p-5 py-2 md:py-8 md:p-8 rounded-[10px] sticky top-28">
								<Filter brands={brands} setBrands={setBrands} levels={levels} setLevels={setLevels} features={features} setFeatures={setFeatures} />
							</div>
						</div>
					</div>
					<div className="col-span-9">
						<div className="space-y-10">
							<Brands />
							<Callback />
							<ProductsSearch />
							<div className="space-y-12">
								<ProductsList label="Phonak" />
								<ProductsList label="Signia" />
								<ProductsList label="Starkey" />
								<ProductsList label="ReSound" />
								<ProductsList label="Oticon" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}


export const ProductsSearch = () => {

	let parsed

	if (typeof window !== `undefined`) {
		parsed = queryString.parse(location.search, { arrayFormat: 'comma' });
	}

	const handleSearch = (e) => {
		e.preventDefault()

		parsed.search = e.target[1].value

		const stringified = queryString.stringify(parsed, { arrayFormat: 'comma' })
		const newUrl = '/products' + '?' + stringified

		navigate(newUrl)

	}

	return (
		<form action="" className="hidden md:block relative" onSubmit={handleSearch}>
			<button className="absolute top-1/2 transform -translate-y-1/2 left-6 w-4 fw-svg">
				<Search />
			</button>
			<input type="text" className="form-input outline-none !pl-16" placeholder="Search for a product" />
		</form>
	)
}


export const Brands = () => {
	return (
		<Manufacturers />
	)
}


export const Callback = () => {
	return (
		<div className="bg-blue text-white px-10 py-12 rounded-[10px] relative space-y-8">
			<div className="space-y-4">
				<h3 className="t-28">Need help choosing?</h3>
				<p className="t-18 text-white text-opacity-[.65]">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
			</div>
			<LinkInner link={{ url: '/book-a-consultation', title: 'Request a callback' }} />
			<StaticImage src="../../assets/images/call.png" alt="" className="w-[113px] object-contain !absolute top-6 right-6" />
		</div>
	)
}

export const ProductsList = ({ label }) => {
	return (
		<div className="space-y-4">
			<h2 className="t-32">{label}</h2>
			<div className="bg-white card-shadow rounded-[10px] px-5 p-10 md:px-10 space-y-10">
				<div>
					<FeaturedProduct title="Audeo Paradise 90" />
				</div>
				<div>
					<div className="space-y-10 sm:space-y-0 sm:grid sm:gap-x-5 grid-cols-3 md:gap-x-10">
						<SingleProduct title="Audeo Paradise 70" />
						<SingleProduct title="Audeo Paradise 50" />
						<SingleProduct title="Audeo Paradise 30" />
					</div>
				</div>
			</div>
		</div>
	)
}

export const FeaturedProduct = ({ title }) => {
	return (
		<div>
			<Link to="/product/audeo-paradise-90" className="block">
				<div className="space-y-5 md:space-y-0 block md:grid grid-cols-3 items-center pb-10 border-b border-b-black border-opacity-10">
					<div className="col-span-1">
						<StaticImage src="../../assets/images/product.png" alt="" className="" />
					</div>
					<div className="col-span-2">
						<div className="space-y-5 max-w-[475px]">
							<div className="flex space-x-2 items-center">
								<div className="bg-blue text-white text-[12px] font-bold px-2 py-1 rounded-[40px]">Platinum</div>
								<div className="flex items-center space-x-1 text-blue">
									{[...Array(3)].map((item, i) => (
										<Star />
									))}
								</div>
							</div>
							<h5 className="t-36">{title}</h5>
							<p className="t-black-65 max-w-[366px]">The Phonak Audeo Paradise P90 is the most advanced hearing aid from Phonak. </p>
							<div className="flex flex-col space-y-3 sm:space-y-0 sm:flex-row sm:justify-between sm:items-end">
								<Price />
								<div className="w-full sm:w-auto">
									<LinkInner link={{ url: '/product/audeo-paradise-90', title: 'View product' }} type="button-outline" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</Link>
		</div>
	)
}


export const SingleProduct = ({ title }) => {
	return (
		<Link to="/product/audeo-paradise-90" className={`block`}>
			<div className="space-y-2">
				<div className="flex space-x-2 items-center">
					<div className="bg-blue text-white text-[12px] font-bold px-2 py-1 rounded-[40px]">Platinum</div>
					<div className="flex items-center space-x-1 text-blue">
						{[...Array(3)].map((item, i) => (
							<Star />
						))}
					</div>

					{/* {product?.product?.postTypeProduct?.productVendor && <div className="text-navy text-[12px] font-bold px-2 py-1 rounded-[40px]">{product?.product?.postTypeProduct?.productVendor}</div>} */}
					{/* {product.logo && <MediaImage image={product.logo} className="w-[64px]" />} */}
				</div>
				<h5 className="t-20">{title}</h5>
				<div className="space-y-2">
					<StaticImage src="../../assets/images/product.png" alt="" className="" />
					<Price />
				</div>
				<div className="fw-button !mt-6">
					<LinkInner link={{ url: '/product/audeo-paradise-90', title: 'View product' }} type="button-outline" />
				</div>
			</div>
		</Link>
	)
}

export const Price = () => {
	return (
		<div className="">
			<div className="italic">Starting from</div>
			<div className="t-24 text-navy">$3000</div>
			<div className="t-14 text-blue">Or $70/month for 6 months</div>
		</div>
	)
}

export const Filter = ({ brands, setBrands, levels, setLevels, features, setFeatures }) => {

	const [expanded, setExpanded] = useState(null);

	const { allWcProductsAttributes } = useProductAttributes();

	return (
		<div>
			{allWcProductsAttributes?.nodes?.map((node, i) => (
				<div className="border-t border-t-black border-opacity-5 py-4  first:border-t-0" key={`faq${i}`}>
					<header className="relative cursor-pointer group pr-5" onClick={() => setExpanded(expanded === i ? null : i)}>
						<div className={`font-semibold text-navy`}>
							{node.name}
						</div>
						<div className={`text-navy  absolute top-1.5 right-0 transition-all duration-200 fw-svg w-1.5 h-auto ease-in-out ${expanded === i ? '-rotate-90 ' : 'rotate-90'
							} `}
						>
							<ChevronRight />
						</div>
					</header>
					<AnimatePresence>
						{expanded === i && (
							<motion.div
								className="overflow-hidden"
								key="content"
								initial="collapsed"
								animate="open"
								exit="collapsed"
								variants={{
									open: { opacity: 1, height: 'auto' },
									collapsed: { opacity: 0, height: 0 },
								}}
								transition={{ duration: 0.3 }}
							>
								<div className="py-2 pt-4 space-y-2">
									{node?.attribute_options?.map((option, i) => (
										<FilterItem type={node?.slug} label={option?.name} slug={option?.slug} brands={brands} setBrands={setBrands} levels={levels} setLevels={setLevels} features={features} setFeatures={setFeatures} />
									))}

								</div>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			))}
		</div>
	)
}


export const FilterItem = ({ type, label, slug, brands, setBrands, levels, setLevels, features, setFeatures }) => {


	const inputRef = useRef(null);

	const updateFilter = (e) => {

		if (type === 'pa_brand') {
			if (e.target.checked) {
				setBrands(brands => [...brands, slug])
			} else {
				setBrands(brands => brands.filter(brand => brand !== slug))
			}
		} else if (type === 'pa_level') {
			if (e.target.checked) {
				setLevels(levels => [...levels, slug])
			} else {
				setLevels(levels => levels.filter(level => level !== slug))
			}
		} else if (type === 'pa_feature') {
			if (e.target.checked) {
				setFeatures(features => [...features, slug])
			} else {
				setFeatures(features => features.filter(feature => feature !== slug))
			}
		}
	}

	useEffect(() => {
		if (type === 'pa_brand') {
			if (brands.includes(slug)) {
				inputRef.current.checked = true
			}
		}
		if (type === 'pa_level') {
			if (levels.includes(slug)) {
				inputRef.current.checked = true
			}
		}
		if (type === 'pa_feature') {
			if (features.includes(slug)) {
				inputRef.current.checked = true
			}
		}
	}, [brands, levels, features])


	return (
		<label className={`flex !text-black items-start flex-auto relative space-y-1 group `}>
			<input
				ref={inputRef}
				type="checkbox"
				style={{ flex: '0 0 18px' }}
				onChange={(e) => updateFilter(e)}
				className={`checkbox-green cursor-pointer mt-2 rounded-[2px] w-[18px] h-[18px] border border-[#ABABAB] group-hover:border-navy focus-within:border-navy bg-transparent appearance-none outline-none group-hover:border-opacity-100 focus:border-opacity-100 checked:border-opacity-100 transition-colors duration-200 ease-in-out `}
			/>
			<div
				className={`t-15 text-left cursor-pointer pl-2.5`}
				dangerouslySetInnerHTML={{ __html: label }}
			/>
		</label>
	)
}
