import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import BookAConsultationData from '../../data/book-a-consultation.json'
import { Check, Cross } from "~/components/elements/Icon";
import { AnimatePresence, motion } from "framer-motion";
import { Slide } from "~/components/landing/FindYourProduct"
import trustpilot from '~/assets/images/trustpilot.svg'
import IframeResizer from 'iframe-resizer-react';

export const BookAConsultation = (page) => {

	const [active, setActive] = useState(0)

	return (
		<section className="bg-blue-light min-h-screen flex items-center">
			<div className="container w-full flex-1 relative pb-20 z-10 h-full flex flex-col justify-center">
				<AnimatePresence>
					<SlideIntro i={0} active={active} setActive={setActive} />
					<SlideCalendar i={1} active={active} setActive={setActive} />
				</AnimatePresence>
				<div className="trustpilot-widget w-full mt-20" data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5fc67a080737a300019b2dd4" data-style-height="20px" data-style-width="100%" data-theme="light" data-text-color="#0B3F6F">
					<a href="https://www.trustpilot.com/review/yeshearing.com" target="_blank" rel="noopener"><span className="hidden">Trustpilot</span></a>
				</div>
			</div>
		</section>
	);
};


export const SlideIntro = ({ i, active, setActive }) => {
	return (
		<>
			{active === i && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.5, ease: "easeInOut" }}
					className="text-center min-h-[60vh] pt-40 flex flex-col items-center justify-start space-y-12">
					<h2 className="t-42 max-w-[643px] text-center">Speak with a Hearing Specialist and get personalized recommendations</h2>
					<div className="grid md:grid-cols-2 gap-4 max-w-[600px] mx-auto">
						<div className="bg-white rounded-[20px] overflow-hidden transform-gpu card-shadow px-3 py-8 space-y-4">
							<div className="h-[76px] flex items-center">
								<StaticImage src="../../assets/images/book-a-consultation-1.png" alt="" className="w-[76px] mx-auto" />
							</div>
							<h6 className="t-18">What to expect</h6>
							<p className="t-black-65">
								Our phone consultations last for 30 minutes where you'll speak with a Specialist to discuss available options.
							</p>
						</div>
						<div className="bg-white rounded-[20px] overflow-hidden transform-gpu card-shadow px-3 py-8 space-y-4">
							<div className="h-[76px] flex items-center">
								<StaticImage src="../../assets/images/book-a-consultation-2.png" alt="" className="w-[145px] mx-auto" />
							</div>
							<h6 className="t-18">Our Specialists</h6>
							<p className="t-black-65">
								We’re the hearing Specialists, with years of audiology experience helping people just like you.
							</p>
						</div>
					</div>

					<button onClick={() => setActive((i + 1))} className="button button-secondary min-w-[326px]">
						<span>Book A Free Consultation</span>
						<span aria-hidden={true}>Book A Free Consultation</span>
					</button>

				</motion.div>
			)}
		</>
	)
}

export const SlideCalendar = ({ i, active, setActive }) => {
	return (
		<>
			{active === i && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.5, ease: "easeInOut" }}
					className="h-screen pt-20 w-full">
					<IframeResizer src="https://calendly.com/yeshearingteam?embed_domain=yeshearing.com&amp;embed_type=PopupWidget" width="100%" height="100%" />
				</motion.div>
			)}
		</>
	)
}

export const SlideThanks = () => {
	return (
		<div>SlideThanks</div>
	)
}
