import React, { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { ChevronRight } from '~/components/elements/Icon';

export const Legal = (page) => {
	const { heading, sections } = page.legal;
	return (
		<>
			<Masthead heading={heading} />
			<Content sections={sections} />
		</>
	);
};

export const Masthead = ({heading}) => {
	return (
		<section className="section section-top">
			<div className="container text-center py-10">
				<h1 className="t-75">{heading}</h1>
			</div>
		</section>
	)
}



export const Content = ({sections}) => {

	const [active, setActive] = useState(0)
	const contentRef = useRef();


	// useEffect(() => {
  //   const blocks = document.querySelectorAll(".legal-content-block");

  //   const onScroll = (e) => {
			
  //     const scrollTop = e.target.documentElement.scrollTop;
  //     const blocks = document.querySelectorAll(".legal-content-block");
  //     const top = contentRef.current.getBoundingClientRect().top + window.scrollY;

  //     if (!blocks) return;
  //     let count = 0;
  //     let active = 0;
  //     let found = false;
  //     for (let block of blocks) {
  //       if (Math.abs(top) > Math.abs(scrollTop - block.offsetTop)) {
  //         active = count;
  //         found = true;
  //       }
  //       count++;
  //     }
  //     if (found) {
  //       setActive(active);
  //     }
  //   };
  //   window.addEventListener("scroll", onScroll);

  //   return () => window.removeEventListener("scroll", onScroll);
  // }, []);

	const scrollToSection = (label) => {

		const blocks = document.querySelectorAll(".legal-content-block");

		blocks.forEach((block, i) => {
			if(block.dataset.label === label){
				window && window.scrollTo({ top: (block.getBoundingClientRect().top + window.scrollY) - 100, behavior: "smooth" });
			}
		})
  };


	return (
		<section className="section">
			<div className="container" ref={contentRef}>
				<div className="xl:grid grid-cols-12">
					<div className="hidden xl:block col-span-3 relative">
						<nav className="sticky top-28">
							<ul className="space-y-4">
								{sections.map((section, i) => (
									<li>
										<button onClick={() => scrollToSection(section.label)} className={`t-24 text-navy group flex items-center justify-between transition-opacity duration-200 ease-in-out opacity-100 hover:opacity-50 w-full`}>
											{section.label} 
											<div className={`transition-opacity duration-200 ease-in-out group-hover:opacity-100 opacity-0 `}>
												<ChevronRight />
											</div>
										</button>
									</li>
								))}
							</ul>
						</nav>
					</div>
					<div className="col-span-8 col-start-5" >
						{sections.map((section, i) => (
							<div className="legal-content-block" data-label={section.label}>
								<h2 className="t-32">{section.label}</h2>
								<div id={section.label} className="mx-auto xl:mx-0 prose py-12 first:pt-0" dangerouslySetInnerHTML={{__html: section.content}} />
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	)
}
