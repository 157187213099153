import React, { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { ChevronRight } from '~/components/elements/Icon';
import { Items } from '~/components/flexible/Faqs';

export const Faqs = (page) => {
	const { heading, content, faqs } = page.faqs;
	return (
		<>
			<Masthead heading={heading} content={content} />
			<FaqsInner faqs={faqs} />
		</>
	);
};

export const Masthead = ({heading, content}) => {
	return (
		<section className="section section-top section-bot bg-blue-light">
			<div className="container">
				<div className="max-w-[853px] mx-auto text-center space-y-5">
					<h1 className="t-75">{heading}</h1>
					<p className="t-18 max-w-[587px] mx-auto">{content}</p>
				</div>
			</div>
		</section>
	)
}



export const FaqsInner = ({faqs}) => {

	const [active, setActive] = useState(0)
	const contentRef = useRef();


	// useEffect(() => {
  //   const blocks = document.querySelectorAll(".faqs-block");

  //   const onScroll = (e) => {
			
  //     const scrollTop = e.target.documentElement.scrollTop;
  //     const blocks = document.querySelectorAll(".faqs-block");
  //     const top = contentRef.current.getBoundingClientRect().top;
  //     const bot = contentRef.current.getBoundingClientRect().bottom;

	// 		console.log(top)
	// 		console.log(bot)
	// 		console.log(scrollTop)

  //     if (!blocks) return;
  //     let count = 0;
  //     let active = 0;
  //     let found = false;
  //     for (let block of blocks) {

  //       // if (Math.abs(top) > Math.abs(scrollTop - block.offsetTop)) {
	// 			if (Math.abs(top) > Math.abs(scrollTop - block.offsetTop)) {
  //         active = count;
  //         found = true;
	// 				if (found) {
	// 					setActive(active);
	// 				}
	// 				return
  //       }
  //       count++;
  //     }
      
  //   };
  //   window.addEventListener("scroll", onScroll);

  //   return () => window.removeEventListener("scroll", onScroll);
  // }, []);


	const scrollToSection = (label) => {

		const blocks = document.querySelectorAll(".faqs-block");

		blocks.forEach((block, i) => {
			if(block.dataset.label === label){
				window && window.scrollTo({ top: (block.getBoundingClientRect().top + window.scrollY) - 100, behavior: "smooth" });
			}
		})
  };


	return (
		<section className="section section-bot bg-blue-light">
			<div className="container" ref={contentRef}>
				<div className="lg:grid grid-cols-12">
					<div className="hidden lg:block col-span-3 relative">
						<nav className="sticky top-28">
							<ul className="space-y-4">
								{faqs.map((section, i) => (
									<li>
										<button onClick={() => scrollToSection(section.label)} className={`t-24 text-navy group flex items-center justify-between transition-opacity duration-200 ease-in-out opacity-100 hover:opacity-50 w-full`}>
											{section.label} 
											<div className={`transition-opacity duration-200 ease-in-out group-hover:opacity-100 opacity-0 `}>
												<ChevronRight />
											</div>
										</button>
									</li>
								))}
							</ul>
						</nav>
					</div>
					<div className="col-span-8 col-start-5" >
						<div className="space-y-16 lg:space-y-24">
							{faqs.map((faqs, i) => (
								<div className="space-y-5 lg:space-y-10 faqs-block" data-label={faqs.label}>
									<h3 className="t-32">{faqs.label}</h3>
									<Items faqs={faqs.questions} />
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
